@import "fonts";

$page-margin: 5rem;
$ideal-cell-width: 20rem;
$ideal-cell-height: 10rem;
$cell-gap: 0.5rem;

body {
	margin: #{$page-margin / 2};
	background: #fef;
}

.grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: $cell-gap;
	width: calc(100vw - #{$page-margin});

	> section {
		padding: 0 1em;
	}

	> * {
		border-radius: 0.5rem;
		box-shadow: 0 0.1rem 0.2rem #0006;
		padding: 0.5rem;
		min-height: $ideal-cell-height;
		box-sizing: border-box;
		background: white;

		&.w2 { grid-column-end: span 2; }
		&.h2 {
			min-height: #{$ideal-cell-height * 2 + $cell-gap};
			grid-row-end: span 2;
		}
	}

	> a {
		transition: background-size 300ms;
		position: relative;

		&:focus {
			outline: none;
			&::after {
				content: '';
				position: absolute;
				pointer-events: none;
				inset: 0.5em;
				outline: 3px solid rgb(0, 125, 208);
				border-radius: 0.15em;
			}
		}

		&.no-text {
			padding: 1em;
			&::before {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background: #fffd;
				transition: opacity 300ms;
				content: '';
				opacity: 0;
				border-radius: 0.5rem;
			}
			display: flex;
			flex-direction: column;
		    align-items: center;
		    justify-content: center;
			> * {
				position: relative;
				opacity: 0;
				transition: opacity 300ms;
				text-align: center;
				font-size: 1rem;
				text-shadow:
					0 0 0.1rem white,
					0 0 0.2rem white,
					0 0 0.3rem white;
			}
			> h3 {
				font-size: 2rem;
				margin: 0;
			}
			background-size: 8em;
			background-position: 50% 50%;
			background-repeat: no-repeat;
			&:hover, &:focus {
				background-size: 9em;
				&.w2.h2 {
					background-size: 18em;
				}
				&::before, * { opacity: 1; }
			}

			&.cover {
				background-size: 100%;
				&:hover, &:focus {
					background-size: 112.5%;
				}
			}

			&.cover-large {
				background-size: 150%;
				&:hover, &:focus {
					background-size: 170%;
				}
			}
		}

		text-decoration: none;
		color: unset;
	}
}

@media screen and (max-width: $ideal-cell-width * 2.5) {
	.grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: $ideal-cell-width * 1.5) {
	.grid {
		grid-template-columns: repeat(1, 1fr);
		section {
			&.w2 { grid-column-end: span 1; }
		}
	}
}

@media screen and (min-width: $ideal-cell-width * 3.5) {
	.grid {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media screen and (min-width: $ideal-cell-width * 4.5) {
	.grid {
		grid-template-columns: repeat(5, 1fr);
	}
}

@media screen and (min-width: $ideal-cell-width * 5.5) {
	.grid {
		margin: 0 auto;
	}
}

@media screen and (min-width: $ideal-cell-width * 6 + $cell-gap * 5 + $page-margin) {
	.grid {
		width: #{$ideal-cell-width * 6 + $cell-gap * 5};
	}
}
