// We use a fancy and distinctive font for headers.
@font-face {
	font-family: 'Taste';
	src: url('../fonts/Taste Book.otf');
	font-weight: normal;
	font-style: normal;
}
h1, h2 {
	font-family: 'Taste';
}

// We use a similar but more readable font with loads of glyphs for the body text.
@import url('https://fonts.googleapis.com/css?family=Lato');

body {
	font-family: 'Lato', 'Open Sans', 'Avenir', 'Product Sans', 'Helvetica', 'Arial', sans-serif;
}

@mixin bold-text {
	font-weight: 700;
	font-style: normal;
}
@mixin normal-text {
	font-weight: 400;
	font-style: normal;
	letter-spacing: 0;
}
